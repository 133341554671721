import { useContext, useEffect, useState } from "react";
import { Resume } from "../../model/result.model";
import ApiServices from "../../services/api.service";
import ResumeListComponent from "../result/resume-list/resume-list"
import { SigninContext } from "../../context/signin.context";
import tokenService from "../../services/token.service";
import config from "../../config";
import ApiConfig from "../../api-config";
import ContactUsModal from "../../modal/contact-us.modal";
import HeaderComponent from "../header/header";
import { ProfileModel } from "../../model/profile.model";
import "../search/search.scss";
import "../search/result/result-search.scss";
import GAConfig from "../../ga-config";
import GAServices from "../../utils/ga-services";
import BreadcrumbComponent from "../breakcrumb/breadcrumb";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useSearchParams } from "react-router-dom";
import { HistoryCandidateModel } from "../../model/history-candidate.model";


const BookmarksComponent = () => {
    const [resultModel, setResultModel] = useState<Resume[]>([]);
    const [sourceKey, setSourceKey] = useState("");
    const { versionGlobal, setUuidPassGlobal }: any = useContext(SigninContext);
    const [idCandidateInterest, setIdCandidateInterest] = useState("");
    const [isShowContactUs, setIsShowContactUs] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [loading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyToken, setKeyToken] = useState("");
    const location = useLocation();
    const [clientID, setClientID] = useState("")
    const [historyCandidateList, setHistoryCandidateList] = useState<HistoryCandidateModel[]>();
    useEffect(() => {
        if (location.pathname.includes("bookmarks")
            && (searchParams.get("client_id") == "TALENTSAUCE" || searchParams.get("client_id") == "HR9CLINIC") && searchParams.get('keyToken')) {
            setKeyToken(searchParams.get('keyToken') + "");
            tokenService.setLocalAccessToken(searchParams.get('keyToken'));
            setClientID(searchParams.get("client_id")+"")
            setUuidPassGlobal(searchParams.get('uuid'));
        } else if (searchParams.get("client_id") == "TALENTSAUCE" || searchParams.get("client_id") == "HR9CLINIC") {
        } else if (location.pathname.includes("preview")) {
        }else if(tokenService.getLocalAccessToken()){
            console.log(tokenService.getLocalAccessToken());
            console.log(clientID);
            setClientID("HR9CLINIC");
            setKeyToken(tokenService.getLocalAccessToken()+"")
        }
    },[])

  

    useEffect(() => {
        if(clientID){
            if (clientID == "TALENTSAUCE") {
                setSourceKey(config.sourceKey.tech);
            } else {
                if (versionGlobal === ApiConfig.version.internal) {
                    setSourceKey(config.sourceKey.internal)
                } else if (versionGlobal === ApiConfig.version.be_8external) {
                    setSourceKey(config.sourceKey.external)
                } else {
                    setSourceKey("")
                }
            }
            getHistoryOpenCandidate(clientID, keyToken)
        }

    },[clientID && keyToken]);

    useEffect(() => {

        if(historyCandidateList){
            getFavorite(clientID,sourceKey, keyToken);
        }
    },[historyCandidateList])


    const getFavorite = (clientID,source_key, key) => {
        ApiServices().getFavoriteCandidate(clientID, key, source_key).then((res) => {
            if (res.status == 200) {
                const result = res.data as Resume[];
                const updatedUsers = result.map(item => {

                    if (historyCandidateList) {
                        historyCandidateList.filter((value => Number(value.candidate_id) == item.candidate_id)).map((value: HistoryCandidateModel) => {
                            console.log(Number(value.candidate_id) + " : " + item.candidate_id)
                            item.is_open = true;
                        })
                    }
                    return {
                        ...item, // Spread operator to copy all existing key-value pairs
                        is_bookmark: true, // Update or add new key1
                        // Add or update more keys as needed
                    };
                });
                setIsLoading(false);
                console.log(updatedUsers);
                setResultModel(updatedUsers);
               
            }
        }).catch((err) => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if(resultModel){
            console.log(resultModel);
        }
    },[resultModel])
    const saveFavorite = (item) => {
        const resume = item as Resume;
        console.log(resume);
        if (resume.candidate_id) {
            ApiServices().saveFavoriteCandidate(clientID, sourceKey, resume.candidate_id, keyToken).then((res) => {
                console.log(res);
                if (res.status == 200) {
                    GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.save_bookmark }, versionGlobal)
                    updateListBookMark(resume.candidate_id, true)
                }

            })
        }
    }
    const removeFavorite = (item, source_key) => {
        const resume = item as Resume;
        if (resume.candidate_id) {
            ApiServices().deleteFavoriteCandidate(clientID, sourceKey, resume.candidate_id, keyToken).then((res) => {
                console.log(res);
                if (res.status == 200) {
                    GAServices().clickSendEvent(GAConfig.name.click, { param_action_on: GAConfig.value.remove_bookmark }, versionGlobal)
                    updateListBookMark(resume.candidate_id, false)
                }

            })
        }
    }
    const updateListBookMark = (candidateID, isSave) => {
        if (resultModel) {
            const resumeModEdit = [...resultModel];

            resumeModEdit.map((item, index) => {
                if (!isSave) {
                    if (item.candidate_id == candidateID) {
                        console.log("SLICE");
                        resumeModEdit.splice(index, 1)
                    }
                }
            })
            console.log(resumeModEdit);
            setResultModel(resumeModEdit);
        }
    };
    const clickInteresCandidate = (item: Resume) => {
        if (item) {
            console.log(item);
            setIdCandidateInterest(item.candidate_id + "")
            setIsShowContactUs(true)
        }

    }
    const handleCloseContactUs = () => {
        setIsShowContactUs(false);
    }
    const getHistoryOpenCandidate = (client_id, keyToken) => {
        if (client_id == "TALENTSAUCE") {
            ApiServices().getHistoryOpenTalentSauce(keyToken).then((res) => {
                if (res) {
                    setHistoryCandidateList(res);
                }
            }).catch((err) => {
                setHistoryCandidateList([]);
            })
        } else {
            ApiServices().getHistoryOpen(keyToken).then((res) => {
                if (res) {
                    setHistoryCandidateList(res);
                }
            }).catch((err) => {
                setHistoryCandidateList([]);
            })
        }

    }

    return (

        <>
            <div className="">

                <HeaderComponent preview={false} setProfileMod={setProfileMod} ></HeaderComponent>
                {/* <BreadcrumbComponent></BreadcrumbComponent> */}
                <div className="result-search-main">
                    <div className="result-box-candidate-control">
                        {
                            resultModel &&
                            <ResumeListComponent
                                clickInteresCandidate={clickInteresCandidate}
                                saveFavorite={saveFavorite}
                                removeFavorite={removeFavorite}
                                resumeModelPass={resultModel}
                                versionModel={versionGlobal}
                                isImportMode={false}></ResumeListComponent>
                        }
                        {
                            resultModel.length == 0 && !loading &&
                            <div className="bookmark-loading-control">
                                <div className="bookmark-notfound-control">
                                    <span className="bookmark-notfound-text">ไม่พบผู้สมัครที่บุ๊คมาร์ค</span>
                                </div>
                            </div>
                        }
                        {
                            loading &&
                            <div className="bookmark-loading-control">
                                <div className="loadmore-candidate-control">
                                    <div className="loadmore-candidate-main">
                                        <CircularProgress className="loadmore-progress"></CircularProgress>
                                        <span className="loadmore-text">กำลังโหลด</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {
                    isShowContactUs &&
                    <ContactUsModal open={isShowContactUs} handleClose={handleCloseContactUs} item={idCandidateInterest}></ContactUsModal>
                }
            </div >

        </>
    )
}

export default BookmarksComponent;