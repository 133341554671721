import config from "../config";
import instance from "./auth.service";


const getProfile = () => {
    return instance.get(config.authen.api.profile);
  };

  
  const MysaService = {
    getProfile
  };
  
  export default MysaService;